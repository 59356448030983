<template>
  <div>
    <vx-card class="tabulator_card">
      <vs-row class="mb-3">
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
          <treeselect
            v-model="selected_column"
            :multiple="true"
            :options="treeDataHide"
            placeholder="Hide Column"
          />
        </vs-col>
        <vs-col
          vs-w="3"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button color="#0044BA" size="small" @click="HideColumn">
            Hide - Unhide Columns
          </vs-button>
        </vs-col>
      </vs-row>
      <div ref="table2" id="example-table-theme"></div>
    </vx-card>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
export default {
   components: {
    Treeselect,
  },
  props: ["visitor_table_details"],
  mounted() {
    console.log("tableData_visitor", this.visitor_table_details);
    this.getVisitorDetails();
    for (let i = 0; i < this.visitor_table_details.length; i++) {
      const element = this.visitor_table_details[i];
      const visit_type = {
        visit: "Visit",
        call: "Call",
        email: "Email",
        whatsapp: "Whatsapp",
      };
      element.type = visit_type[element.type];
      if (element.first_visit_date !== null) {
        element.first_visit_date = moment(element.first_visit_date).format(
          "DD-MMM-YYYY"
        );
      }
      if (element.enrollment_date !== null) {
        element.enrollment_date = moment
          .unix(element.enrollment_date)
          .format("DD-MMM-YYYY");
      }
      if (element.last_visit_date !== null) {
        element.last_visit_date = moment(element.last_visit_date).format(
          "DD-MMM-YYYY"
        );
      }
      if (element.created_at !== null) {
        element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
      }
      if (element.last_call !== null) {
        element.last_call = moment
          .unix(element.last_call)
          .format("DD-MMM-YYYY");
      }
      if (element.next_call !== null) {
        element.next_call = moment
          .unix(element.next_call)
          .format("DD-MMM-YYYY");
      }
      if (element.mhp_date !== null) {
        element.mhp_date = moment.unix(element.mhp_date).format("DD-MMM-YYYY");
      }
      Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
    }
  },
  data() {
    return {
      visitor_details: [],
      selected_column: [],
      treeDataHide:[
        {
          id:"company",
          label:"Company"
        },
        {
          id:"experience",
          label:"Experience"
        },
        {
          id:"education",
          label:"Education"
        },
        {
          id:"level",
          label:"Level"
        },
        {
          id:"enrollment_date",
          label:"Enrollment Date"
        },
        {
          id:"courses",
          label:"Course"
        },
        {
          id:"cm_spoc_name",
          label:"CM SPOC"
        },
        {
          id:"sr_spoc_name",
          label:"SR SPOC"
        },

        {
          id:"visit_status",
          label:"Visit Status"
        },
        {
          id:"type",
          label:"Visit Type"
        },
        {
          id:"first_visit_date",
          label:"First Visit Date"
        },
        
        {
          id:"last_visit_date",
          label:"Last Visit Date"
        },
        {
          id:"created_at",
          label:"Lead Created Date"
        },
        {
          id:"last_call",
          label:"Last Call"
        },
        {
          id:"next_call",
          label:"Next Call"
        },
        {
          id:"fam_lam.first_source",
          label:"FAM"
        },
        {
          id:"fam_lam.last_source",
          label:"LAM"
        },
        {
          id:"mhp_date",
          label:"MHP Date"
        },
        
      ],
    };
  },
  watch: {
    visitor_table_details(val) {
      this.visitor_details = val;
      console.log("visitor_value", val);
      this.getVisitorDetails();
      for (let i = 0; i < this.visitor_details.length; i++) {
        const element = this.visitor_details[i];
        const visit_type = {
          visit: "Visit",
          call: "Call",
          email: "Email",
          whatsapp: "Whatsapp",
        };
        element.type = visit_type[element.type];
        if (element.first_visit_date !== null) {
          element.first_visit_date = moment(element.first_visit_date).format(
            "DD-MMM-YYYY"
          );
        }
        if (element.enrollment_date !== null) {
          element.enrollment_date = moment
            .unix(element.enrollment_date)
            .format("DD-MMM-YYYY");
        }

        if (element.last_visit_date !== null) {
          element.last_visit_date = moment(element.last_visit_date).format(
            "DD-MMM-YYYY"
          );
        }
        if (element.created_at !== null) {
          element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.mhp_date !== null) {
          element.mhp_date = moment
            .unix(element.mhp_date)
            .format("DD-MMM-YYYY");
        }
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
    },
  },
  methods: {
    getVisitorDetails() {
      this.tabulator = new Tabulator(this.$refs.table2, {
        maxHeight: "80vh",
        data: this.visitor_details,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
        columns: [
        
          {
            title: "Can-ID",
            field: "identity",
            frozen: true,
            sorter: "alphanum",
          },
          {
            title: "Candidate Name",
            field: "person_name",
            frozen: true,
          },
          {
            title: "Company",
            field: "company",
          },
          {
            title: "Experience",
            field: "experience",
          },
          {
            title: "Education",
            field: "education",
          },
          {
            title: "Level",
            field: "level",
            sorter: "alphanum",
          },
          {
            title: "Enrollment Date",
            field: "enrollment_date",
            headerSort: false,
          },
          {
            title: "Course",
            field: "courses",
          },
          {
            title: "CM SPOC",
            field: "cm_spoc_name",
          },
          {
            title: "SR SPOC",
            field: "sr_spoc_name",
          },
          {
            title: "Visit Status",
            field: "visit_status",
          },
          {
            title: "Visit Type",
            field: "type",
          },
          {
            title: "First Visit Date",
            field: "first_visit_date",
          },
          {
            title: "Last Visit Date",
            field: "last_visit_date",
          },
          {
            title: "Lead Created Date",
            field: "created_at",
          },
          {
            title: "Last Call",
            field: "last_call",
            headerSort: false,
          },
          {
            title: "Next Call",
            field: "next_call",
            headerSort: false,
          },
          {
            title: "FAM",
            field: "fam_lam.first_source",
            headerSort: false,
          },
          {
            title: "LAM",
            field: "fam_lam.last_source",
            headerSort: false,
          },
          {
            title: "MHP Date",
            field: "mhp_date",
            headerSort: false,
          },
          {
            title: "About",
            field: "info",
            formatter: "image",
            hozAlign: "center",
            frozen:true,
            headerSort:false,
            formatterParams: {
                height: "25px",
                width: "35px",
            },
            cssClass:"visit_about_cell",
            cellClick: this.getId,
          },
        ],
      });
    },
    getId(e, cell) {
      console.log(e, cell._cell.row.data.mwb_id);
      this.openBigPopup(cell._cell.row.data.mwb_id);
    },
    HideColumn(){
      this.treeDataHide.forEach((treeData) => {
           this.tabulator.showColumn(treeData.id)
      });
      if(this.selected_column.length > 0){
        this.selected_column.forEach((selected_item)=>{
          this.treeDataHide.forEach((item) =>{
              if(item.id == selected_item){
                this.tabulator.hideColumn(item.id)
              }
            })
          })
      }
    },
  },
};
</script>

<style>
.tabulator_card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}

.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
.visit_about_cell img{
  padding-inline-start: 10px;
}
</style>